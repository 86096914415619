(function ($) {
  var videoBlockV1 = {
    createVideoPlayerDiv: function ($elem, i) {
      var id = $elem.attr('class') + '-' + i;

      $elem
        .append('<div />')
        .children('div')
        .attr('id', id);

      return id;
    },

    addVideo: function (ytId, iframeId) {
      var player = new YT.Player(iframeId, {
        height: '100%',
        width: '100%',
        videoId: ytId,
        playerVars: {
        // controls: 0
        },
        events: {
          onReady: function (event) {
          // Don't autoplay on mobile
            if (!Modernizr.touch) {
              event.target.playVideo();
            }
          },
          onStateChange: function (event) {
          // stuff
          },
          onApiChange: function (event) {
          }
        }
      });
    },

    setup: function (event) {
      var self = this;

      $('.js-video-block-slide').each(function (i) {
        var $me = $(this),
          $content = $('.video-block__content', $me),
          $vids = $content.filter(function (i) {
            return ($(this).data('video-provider') == 'youtube' && $(this).data('youtube-id').length > 0) || ($(this).data('video-provider') == 'youku' && $(this).data('youku-id').length > 0);
          }),
          $player = $('.video-block__media--video', $content);

        $vids.each(function () {
          var $wrap = $(this),
            videoType = $wrap.data('video-provider');

          $wrap.click(function (e) {
          // Do something useless
            e.preventDefault();

            // Reset the current player wrapper
            $player.removeClass('active').empty();

            // Create a new div for the player to take over
            var iframeId = self.createVideoPlayerDiv($player, Math.floor(+new Date() / 1000));

            // Add the 'active' class to the player for spacing
            $player.addClass('active');

            // Instantiate the video
            if (videoType === 'youtube') {
              self.addVideo($wrap.data('youtube-id'), iframeId);
            } else if (videoType === 'youku') {
              var youkuVideoIframe = youkuVideoEmbed.addYoukuVideo($wrap.data('youku-id'), iframeId);

              $wrap.find('.video-block__media--video').append(youkuVideoIframe);
            }

            // Do something else that's useless
            return false;
          });
        });
      });
    }
  };

  $(document).bind('youtubeIframeAPI.loaded', function (e) {
    videoBlockV1.setup(e);
  });
})(jQuery);
